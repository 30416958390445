<template>
	<div class="cebian">
		<div class="select" @click="handleClick">
			项目查询
			<a-icon type="menu-unfold" />
		</div>
		<div class="list" :style="{'width':width+'px'}">
			 <a-tree show-line :default-expanded-keys="[]" @select="onSelect">
			    <a-tree-node :title="item.projectName" v-for="(item,index) in list" :key="index">
			    </a-tree-node>
			  </a-tree>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			show:Boolean
		},
		data(){
			return{
				list:[],
				width:160,
        projectIdList: sessionStorage.getItem('projectId'), //根据项目id获取数据
			}
		},
		mounted(){
			this.width=0
			this.handleList()
		},
		methods:{
			handleList(){
				this.$axios(this.api+`/project/list?projectIdList=${this.projectIdList}`).then(res=>{
					this.list = res.data.list
				})
			},
			onSelect(e){
				this.$emit('handleProject',this.list[e].projectId)
				this.$emit('handleShow',false)
			},
			handleClick(){
				if(this.show){ //隐藏
					this.width=0
				}else{//显示
					this.width=160
				}
				this.$emit('handleShow',!this.show)
			}
		}
	}
</script>

<style scoped="scoped">
	.cebian{
		position: fixed;
		top: 100px;
		left: 0;
		
	}
	.select{
		background-color: #2294fc;
		color: #fff;
		line-height: 30px;
		height: 30px;
		padding: 0 10px;
		width: 100px;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
	}
	.select:hover{
		cursor: pointer;
	}
	.list{
		padding-top: 20px;
		background-color: #fff;
		/* width:160px; */
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		height: calc(100vh - 130px);
		overflow-y: auto;
		transition: width 0.5s ease-in-out;
		-webkit-transition:width 0.5s ease-in-out;
		-moz-transition:width 0.5s ease-in-out;
		-ms-transition:width 0.5s ease-in-out;
		-o-transition:width 0.5s ease-in-out;
	}
</style>
