<template>
  <div class="admin">
    <div class="mianbao">
      <div class="breadcrumb">
        <span style="color: #fff"> 综合除治 > </span>
        <span style="color: #016ae0"> 打孔注药详情 </span>
      </div>
    </div>
    <div class="search">
      <div class="top">
        登记编号：
        <a-input
          v-model="form.treeId"
          style="
            width: 15%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入编号"
        ></a-input>
        采集时间：
        <a-range-picker
          :placeholder="['开始时间', '结束时间']"
          style="margin-right: 2%"
          @change="onChange"
        />
        作业人员：
        <a-select
          allowClear
          v-model="form.groupId"
          style="
            width: 120px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option
            :value="item.groupId"
            v-for="(item, index) in groupList"
            :key="index"
          >
            {{ item.groupName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="bottom">
        小班号：
        <a-input
          v-model="form.classId"
          style="
            width: 15%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入小班号"
        ></a-input>
        <a-button
          style="margin-right: 20px"
          type="primary"
          @click="handleLists()"
          >查询</a-button
        >
        <el-upload
          style="margin-right: 20px"
          v-if="form.projectIdList === 9 && groupIdList == 1"
          class="upload-demo"
          :action="this.api + '/injection/import'"
          :headers="headers"
          :on-change="handleChange"
        >
          <el-button size="small" type="primary">信息导入</el-button>
        </el-upload>
        <el-upload
          style="margin-right: 20px"
          v-if="form.projectIdList === 9 && groupIdList == 1"
          class="upload-demo"
          :action="this.api + '/injection/upload/imgCode'"
          :headers="headers"
          :on-change="handleChange"
        >
          <el-button size="small" type="primary">树木编号图导入</el-button>
        </el-upload>
        <el-upload
          style="margin-right: 20px"
          v-if="form.projectIdList === 9 && groupIdList == 1"
          class="upload-demo"
          :action="this.api + '/injection/upload/imgDrug'"
          :headers="headers"
          :on-change="handleChange"
        >
          <el-button size="small" type="primary">树木注药图导入</el-button>
        </el-upload>
      </div>
    </div>
    <div class="content">
      <div class="erweima">
        <div class="list">
          <a-spin class="spin" :spinning="spinning" />
          <div style="width: 100%; height: 42px; display: flow-root">
            <div class="l_list">打孔注药列表</div>
            <div class="l_column">
              <a-button class="l_sort" @click="sortClick">
                <div class="l_type">
                  <div>排序</div>
                  <div>
                    <div class="l_up"><a-icon type="caret-up" /></div>
                    <div class="l_below"><a-icon type="caret-down" /></div>
                  </div>
                </div>
              </a-button>
              <a-button class="l_derive" @click="deriveClick"
                >导出报表</a-button
              >
            </div>
          </div>
          <vue-scroll :ops="ops" style="width: 100%">
            <!-- 打孔注药列表 -->
            <punchingList :list="list" />
            <div class="fenye">
              <!-- 页码 -->
              <a-config-provider :locale="locale">
                <a-pagination
                  @change="onShowSizeChange"
                  @showSizeChange="showSizeChange"
                  v-model="form.pageNum"
                  :pageSizeOptions="pageSizeOptions"
                  :pageSize="form.pageSize"
                  :total="total"
                  show-less-items
                  :showSizeChanger="true"
                />
              </a-config-provider>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
    <!-- 项目查询 -->
    <Cebian
      :show="show"
      @handleShow="handleShow"
      @handleProject="handleProject"
    />
  </div>
</template>

<script>
// import img from '../../assets/u530.png'
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import Cebian from "../../components/cebian.vue";
import punchingList from "./components/punchingList.vue";
import axios from "axios";
export default {
  name: "Punching",
  inject: ["reload"],
  components: {
    Cebian,
    punchingList,
  },
  data() {
    return {
      token: "",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      show: false,
      form: {
        pageNum: 1,
        pageSize: 10,
        projectIdList: sessionStorage.getItem("projectId"), //根据项目id获取数据
      },
      total: null,
      lastPage: 0,
      locale: zhCN, //国际化 设置成中文
      pageSizeOptions: ["10", "15", "20", "25"],
      list: [],
      spinning: false,
      groupList: [],
      order: "ASC",
      options: [],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#F5F5F5", //滚动条颜色
          opacity: 0.5, //滚动条透明度
          "overflow-y": "hidden",
        },
      },
      projectIdList: sessionStorage.getItem("projectId"), //根据项目id获取数据
      groupIdList: sessionStorage.getItem("groupId"),
      username: sessionStorage.getItem("username"),
    };
  },
  mounted() {
    this.handleList();
    this.handleGroup();
    this.getArea(); // 地区
    this.token = sessionStorage.getItem("token");
  },
  methods: {
    // 作业人员列表
    handleGroup() {
      this.$axios(
        this.api + `/group/list?groupIdList=${this.groupIdList}`
      ).then((res) => {
        this.groupList = res.data.list;
      });
    },
    // 项目查询
    handleProject(e) {
      this.form.projectIdList = e;
      this.handleList();
    },
    handleShow(show) {
      this.show = show;
    },
    // 查询
    handleLists() {
      this.handleList();
      setTimeout(() => {
        if (this.form.pageNum !== 1) {
          this.form.pageNum = this.lastPage;
          this.handleList();
        }
      }, 1000);
    },
    // 打孔注药列表
    handleList() {
      this.spinning = true;
      // console.log(this.form)
      this.$axios(this.api + "/injection/list", { params: this.form }).then(
        (res) => {
          this.lastPage = res.data.pages;
          this.total = res.data.total;
          this.list = res.data.list;
          this.spinning = false;
        }
      );
    },
    // 重置
    resetClick() {
      this.reload();
    },
    // 时间
    onChange(value, mode) {
      this.form.startTime = mode[0];
      this.form.endTime = mode[1];
    },
    // 页码
    onShowSizeChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      // this.forms.pageNum = pageNum;
      this.handleList();
    },
    // 每页几条？
    showSizeChange(current, size) {
      this.form.pageSize = size;
      this.handleList();
    },
    // 获取地区
    getArea() {
      this.$axios.get(this.api + "/project/search").then((res) => {
        if (res.code === 200) {
          this.options = this.listToTree(res.data);
        }
      });
    },
    listToTree(list, parentId = "") {
      return list
        .filter((item) => item.parentId === parentId)
        .map((item) => ({
          ...item,
          label: item.orgName,
          value: item.orgName,
          children: this.listToTree(list, item.id),
        }));
    },
    // 排序
    async sortClick() {
      let form = this.form;
      if (this.order === "ASC") {
        this.order = "DESC";
        const res = await this.$axios.get(
          this.api + `/injection/list?projectIdList=${this.projectIdList}`,
          {
            params: {
              order: this.order,
              pageNum: form.pageNum,
              pageSize: form.pageSize,
            },
          }
        );
        return (this.list = res.data.list);
      } else if (this.order === "DESC") {
        this.order = "ASC";
        const res = await this.$axios.get(
          this.api + `/injection/list?projectIdList=${this.projectIdList}`,
          {
            params: {
              order: this.order,
              pageNum: form.pageNum,
              pageSize: form.pageSize,
            },
          }
        );
        return (this.list = res.data.list);
      }
    },
    // 导入
    handleChange(file) {
      if (file.response !== undefined) {
        if (file.response.code !== 200) {
          return this.$message.info(file.response.msg);
        } else {
          return this.$message.success(file.response.msg);
        }
      }
    },
    // 导出报表
    deriveClick() {
      let form = this.form;
      axios({
        method: "get",
        url: this.api + "/injection/downloadExcel", //'/api_request'+
        headers: {
          Authorization: this.token,
        },
        params: {
          username: this.username,
          treeId: form.treeId, // 登记编号
          classId: form.classId, // 小班号
          projectId: form.projectIdList, //项目ID
          groupId: form.groupId, //工作组ID
          startTime: form.startTime,
          endTime: form.endTime,
        }, // 请求参数
        responseType: "blob", // 表明返回服务器返回的数据类型
      }).then((res) => {
        // 处理返回的文件流
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel", //excel文件
        });
        let fileName = "打孔注药信息.xlsx"; //文件名称
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        this.$message.success("下载成功");
        link.click();
      });
    },
  },
};
</script>

<style scoped="scoped">
div /deep/.ant-pagination-item-ellipsis {
  color: #fff !important;
}
div /deep/.ant-select-selection {
  color: #fff;
  background-color: rgba(7, 23, 46, 1);
}
div /deep/.ant-input {
  color: #fff;
  background-color: rgba(7, 23, 46, 1);
}
div /deep/.ant-select-arrow {
  color: rgb(249 240 240 / 91%);
}
div /deep/.ant-calendar-range-picker-separator {
  color: rgb(249 240 240 / 91%);
}
div /deep/.duoxuan > div[data-v-21316321] {
  background-color: rgba(240, 240, 240, 0.1);
  color: #ffffff;
}
.admin {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 10px 40px 20px 40px;
  background: url(../../assets/bg01.jpg) no-repeat;
  background-size: 100% 100%;
}
.mianbao {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellow; */
}
.search {
  width: 100%;
  height: 140px;
  border: 1px solid #016ae0;
  border-radius: 8px;
  color: #ffffff;
  /* background-color: #fff; */
  margin-top: 10px;
  text-align: left;
  /* display: flex; */
  /* align-items: center; */
  padding: 0 20px;
}
.search > div {
  height: 70px;
  display: flex;
  align-items: center;
}
.content {
  width: 100%;
  height: calc(100% - 210px);
  /* background-color: #fff; */
  border: 1px solid #016ae0;
  border-radius: 8px;
  margin-top: 20px;
  padding: 11px;
  color: #ffffff;
}
.button {
  text-align: left;
}
.erweima {
  width: 100%;
  /* margin-top: 8px; */
  height: 100%;
}
.list {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 40px);
  /* position: relative; */
}
.spin {
  position: absolute;
  top: 40%;
  left: 50%;
}
.item {
  width: 23.5%;
  height: calc(50% - 21px);
  margin-bottom: 10px;
}
.fenye {
  height: 32px;
  margin-top: 8px;
  text-align: right;
}
.yuan {
  width: 15px;
  height: 15px;
  border: 1px solid #005ea1;
  border-radius: 50%;
}
.item_center {
  border: 1px solid #005ea1;
  width: 100%;
  margin-top: 15px;
  height: calc(100% - 30px);
}
.number {
  height: 60px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-size: 18px;
}
.img {
  border-top: 1px solid #005ea1;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.img img {
  height: 80%;
  width: auto;
}
/* ------------------------------------------ */
.l_list {
  display: flex;
  float: left;
  align-items: center;
  padding: 0 0rem 10px 4px;
  height: 100%;
  font-size: 16px;
}
.l_column {
  padding: 0 10px 10px 0;
  display: flex;
  float: right;
  align-items: center;
  height: 100%;
}
.l_type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}
.l_column .l_sort {
  margin-right: 1rem;
  border: 1px solid #0099ff;
  background: transparent;
  color: #0099ff;
}
.l_column .l_derive {
  color: #ffffff;
  border: 1px solid #0099ff;
  background: #0066ff;
}
.l_up {
  height: 10px;
}
.l_below {
  height: 19px;
}
.upload-demo {
  display: flex;
}
div >>> .el-upload-list {
  display: none;
}
</style>
