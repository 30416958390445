<template>
	<div class="table">
		<div class="header">
			<div class="list">
				<div
					class="item"
					:style="{ width: item.width + '%' }"
					v-for="(item, index) in columns"
					:key="index"
				>
					{{ item.title }}
				</div>
			</div>
		</div>
		<div class="table_body">
			<div class="body" v-for="item in list" :key="item.treeId">
				<div class="list">
					<div class="item" style="width: 12%" v-clipboard:copy="item.treeId"
v-clipboard:success="onCopy" v-clipboard:error="onError" >
						{{ item.treeId }}
					</div>
					<div class="item" style="width: 8%" v-clipboard:copy="item.diameter"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.diameter }}
					</div>
					<div class="item" style="width: 8%" v-clipboard:copy="item.number"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.number }}
					</div>
          <div class="item" style="width: 19%" v-clipboard:copy="item.place"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.place }}
					</div>
					<div class="item" style="width: 8%" v-clipboard:copy="item.classId"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.classId }}
					</div>
					<div class="item" style="width: 10%" v-clipboard:copy="item.projectName"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.projectName }}
					</div>
					<div class="item" style="width: 10%" v-clipboard:copy="item.groupName"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.groupName }}
					</div>
					<div class="item" style="width: 8%" v-clipboard:copy="item.longitude"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.longitude }}
					</div>
					<div class="item" style="width: 8%" v-clipboard:copy="item.latitude"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.latitude }}
					</div>
          <div class="item" style="width: 12%" v-clipboard:copy="item.collectTime"
v-clipboard:success="onCopy" v-clipboard:error="onError">
						{{ item.collectTime }}
					</div>
					<div class="item item1" style="width: 11%">
						<div class="wei">
							<div
								class=""
								style="color: #09f;"
								@click="handleDetail(item)"
							>
								查看信息
							</div>
              <div
              v-if="groupIdList == 1"
								class=""
								style="color: #09f;"
								@click="amendClick(item)"
							>
								修改
							</div>
              <a-popconfirm
              v-if="groupIdList == 1"
				       	style="display: flex; align-items: center"
				       	title="确认删除"
				       	placement="bottom"
				       	ok-text="确认"
				       	cancel-text="取消"
				       	@confirm="deleteClick(item.treeId)"
				       >
								 删除
              </a-popconfirm>
						</div>
					</div>
				</div>
			</div>
		</div>
    <!-- 查看信息框 -->
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="30%" style="text-align:left;">
      <el-form ref="form" :model="punching" label-width="80px">
      <el-form-item label="登记编号">
        <el-input style="width:80%" v-model="punching.treeId"></el-input>
      </el-form-item>
      <el-form-item label="胸径(cm)">
        <el-input style="width:80%" v-model="punching.diameter"></el-input>
      </el-form-item>
      <el-form-item label="数量(针)">
        <el-input style="width:80%" v-model="punching.number"></el-input>
      </el-form-item>
      <el-form-item label="地点">
        <el-input style="width:80%" v-model="punching.place"></el-input>
      </el-form-item>
      <el-form-item label="小班号">
        <el-input style="width:80%" v-model="punching.classId"></el-input>
      </el-form-item>
      <el-form-item label="项目">
        <el-select v-model="punching.projectId" class="t-job" style="width: 80%;margin-right:40px;" >
				   <el-option :value="item.projectId" :label="item.projectName" v-for="(item,index) in projectList" :key="index">
				        {{item.projectName}}
				   </el-option>
			 </el-select>
      </el-form-item>
      <el-form-item label="工作组">
        <el-select v-model="punching.groupId" class="t-job" style="width: 80%;margin-right:40px;" >
				   <el-option :value="item.groupId" :label="item.groupName" v-for="item in groupList" :key="item.groupId">
				        {{item.groupName}}
				   </el-option>
			 </el-select>
      </el-form-item>
      <el-form-item label="经度">
        <el-input style="width:80%" v-model="punching.longitude"></el-input>
      </el-form-item>
      <el-form-item label="纬度">
        <el-input style="width:80%" v-model="punching.latitude"></el-input>
      </el-form-item>
      <el-form-item label="采集时间">
        <el-input style="width:80%" v-model="punching.collectTime"></el-input>
      </el-form-item>
      <el-form-item label="树木编号照片" v-if="images">
        <div style="width:80%">
        <div v-show="punching.imgCode == ''">暂未上传</div>
        <el-image v-show="punching.imgCode !== ''" style="width:6rem;height:6rem;margin: 0 10px 10px 0;" v-for="(item, index) in punching.imgCode" :key="index" :src="api + '/' + punching.imgPrefix + item" :preview-src-list="[api + '/' + punching.imgPrefix + item]"></el-image>
        </div>
      </el-form-item>
      <el-form-item label="树木注药照片" v-if="images">
        <div style="width:80%">
        <div v-show="punching.imgDrug == ''">暂未上传</div>
        <el-image v-show="punching.imgDrug !== ''" style="width:6rem;height:6rem;margin: 0 10px 10px 0;" v-for="(item, index) in punching.imgDrug" :key="index" :src="api + '/' + punching.imgPrefix + item" :preview-src-list="[api + '/' + punching.imgPrefix + item]"></el-image>
        </div>
      </el-form-item>
      <el-form-item label="树木编号照片" v-if="uimages">
        <div style="width:80%">
          <viewer v-show="punching.imgCode !== ''">
            <div class="img-vast">
              <div class="img-border" v-for="(item, index) in punching.imgCode" :key="index" >
                <el-popconfirm title="确定删除当前图片吗？" @confirm="deleteClicks(1,item,punching.imgCode)">
                 <div slot="reference" class="img-delete">x</div>
                </el-popconfirm>
                <img style="width:6rem;height:6rem;" :src="api + '/' + punching.imgPrefix + item" alt="">
              </div>
            </div>
          </viewer>
        </div>
      </el-form-item>
      <el-form-item label="树木注药照片" v-if="uimages">
        <div style="width:80%">
          <viewer v-show="punching.imgDrug !== ''">
            <div class="img-vast">
              <div class="img-border" v-for="(item, index) in punching.imgDrug" :key="index" >
                <el-popconfirm title="确定删除当前图片吗？" @confirm="deleteClicks(2,item,punching.imgDrug)">
                 <div slot="reference" class="img-delete">x</div>
                </el-popconfirm>
                <img style="width:6rem;height:6rem;" :src="api + '/' + punching.imgPrefix + item" alt="">
              </div>
            </div>
          </viewer>
        </div>
      </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" v-if="uimages" @click="affirmClicks">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Message } from 'element-ui';
export default {
	props: {
		list: Array,
	},
	data() {
		return {
			columns: [
				{
					title: '登记编号',
					dataIndex: 'treeId',
					key: 'treeId',
					width: '12',
				},
				{
					title: '胸径(cm)',
					dataIndex: 'diameter',
					key: 'diameter',
					width: '8',
				},
				{
					title: '数量(针)',
					dataIndex: 'number',
					key: 'number',
					width: '8',
				},
        {
          title: '地点',
          dataIndex: 'place',
          key: 'place',
          width: '19',
        },
				{
					title: '小班号',
					key: 'classId',
					dataIndex: 'classId',
					width: '8',
				},
				{
					title: '项目',
					key: 'projectName',
					dataIndex: 'projectName',
					width: '10',
				},
        {
					title: '工作组',
					key: 'groupName',
					dataIndex: 'groupName',
					width: '10',
				},
				{
					title: '经度',
					key: 'longitude',
					dataIndex: 'longitude',
					width: '8',
				},
				{
					title: '纬度',
					key: 'latitude',
					dataIndex: 'latitude',
					width: '8',
				},
				{
					title: '采集时间',
					key: 'collectTime',
					dataIndex: 'collectTime',
					width: '12',
				},
				{
					title: '操作',
					key: 'action',
					dataIndex: 'tags',
					width: '11',
				},
			],
      locale: zhCN, // 弹框中文
      punching: {}, //信息框信息
      visible: false, //信息框显示/隐藏
      groupIdList: sessionStorage.getItem('groupId'),
      title: '查看详情',
      images: true, // 查看图片展示、隐藏
      uimages: true, // 修改图片展示、隐藏
      dialogVisible: true,
      treeOldId: '', //旧id
      groupList: [], //工作组、作业人员
      projectList: [], //项目列表
		}
	},
  mounted() {
    this.onChange()
    this.onChanges()
  },
	methods: {
  // 复制成功
  onCopy() {
    this.$message.success('复制成功')
  },
  // 复制失败
  onError() {
    this.$message.info('复制失败')
  },
  // 获取工作组/作业人员
  async onChange() {
    const res = await this.$axios.get(this.api + '/group/list')
        if(res.code === 200) {
          this.groupList = res.data.list
        } else {
          this.$message.info('作业人员获取失败，请联系管理人员')
        }
  },
  // 获取项目
  async onChanges() {
    const res = await this.$axios.get(this.api + '/project/list')
        if(res.code === 200) {
          this.projectList = res.data.list
        } else {
          this.$message.info('项目获取失败，请联系管理人员')
        }
  },
    // 查看信息
		handleDetail(item) {
      this.title = '查看详情'
      this.punching = item
      this.visible = true;
      this.images = true
      this.uimages = false
		},
    // 修改
    amendClick(item) {
      this.title = '修改'
      this.punching = item
      this.visible = true;
      this.images = false
      this.uimages = true
      this.treeOldId = item.treeId
    },
    // 删除图片
   async deleteClicks(u,item,e) {
    if(u === 1) {
      if(e.length < 1 || e.length == 1) {
        return Message.info('不可删除，至少保留一张')
      }
    }
    if(u === 2) {
      if(e.length < 1 || e.length == 1) {
        return Message.info('不可删除，至少保留一张')
      }
    }
      const res = await this.$axios.delete(this.api + `/injection/delete/img?srcList=${item}`)
      if(res.code === 200) {
        Message.success('删除成功，请手动刷新')
        e.length = e.length-1
      } else {
        Message.info('删除失败，请重试')
      }
    },
    // 修改提交
   async affirmClicks() {
      if(this.title === '查看详情') {
        this.visible = false;
      } else {
        let data = {
          treeId: this.punching.treeId, // 登记编号
          treeOldId: this.treeOldId, // 旧编号
			    diameter: this.punching.diameter, // 胸径
			    number: this.punching.number, // 数量
			    place: this.punching.place,    // 地点
			    classId: this.punching.classId, // 小班号
			    projectId: this.punching.projectId,    //项目ID
			    groupId: this.punching.groupId,    //工作组ID
			    longitude: this.punching.longitude,    //经度
			    latitude: this.punching.latitude,    //纬度
			    collectTime: this.punching.collectTime, // 时间
       }
        if(this.punching.longitude !== '' && this.punching.latitude !== '') {
          const res1 = await this.$axios.get(
            this.api + `/elimination/treeClass?longitude=${this.punching.longitude}&latitude=${this.punching.latitude}&projectId=${this.punching.projectId}`,
          );
          if (res1.code === 200) {
            if(res1.data !== '') {
              data.classId = res1.data
              this.updateClick(data)
            } else {
              data.classId = this.punching.classId
              this.updateClick(data)
            }
          } else {
            data.classId = this.punching.classId
            this.updateClick(data)
          }
        } else {
           data.classId = this.punching.classId
           this.updateClick(data)
        }
      }
    },
    async updateClick(data) {
      const res = await this.$axios.put(this.api + '/injection/update',data)
        if(res.code === 200) {
          this.visible = false;
          this.$message.success('提交成功，请手动更新')
        } else {
          this.$message.info('更新失败，请重试')
        }
    },
    // 删除
   async deleteClick(e) {
      let treeId = e
      const res = await this.$axios.delete(this.api + '/injection/delete/'+ treeId)
      if(res.code === 200) {
        this.$message.success('已删除，请手动更新')
      } else {
        this.$message.info('删除失败，请重试')
      }
    }
	},
}
</script>

<style scoped>
/* 信息框 */
.e_information >>> .ant-modal-body {
  font-size: 14px;
  color: #333333;
  font-family: 'Arial Normal', 'Arial';
  padding: 5px 20px;
}
.e_information_flex {
  display: flex;
  align-items: center;
  margin: 1.5rem 2rem;
}
.e_information >>> .e_information_left {
  width: 19%;
}
.table {
	width: 100%;
	height: 100%;
	/* background-color: red; */
}
.yuan {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: red;
	margin-right: 5px;
}
.item1 > div {
	display: flex;
}
.yi > div,
.wei > div {
	margin-right: 10px;
	width: 4em;
}
.wei > div:hover {
	cursor: pointer;
}
.header {
	display: flex;
	height: 50px;
	background-color: #1f325e;
	border-bottom: 1px solid #005ea1;
	/* align-items: center; */
}
.header .list {
	background-color: #1f325e;
	width: calc(100% - 40px);
	display: flex;
	align-items: center;
}
.table_body {
	height: calc(100% - 50px);
	overflow-y: auto;
}
.table_body .body {
	display: flex;
	min-height: 50px;
	border-bottom: 1px solid #005ea1;
	/* align-items: center; */
}
.table_body .list {
	/* background-color:#1f325e ; */
	width: calc(100% - 40px);
	display: flex;
	align-items: center;
}
.item {
	display: flex;
	align-items: center;
  justify-content: center;
}
/* 修改图片展示 */
.img-vast {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.img-border {
 border: 1px solid #ccc;
 margin: 0 10px 10px 0;
}
.img-delete {
  height: 16px;
  line-height: 16px;
  text-align: right;
  padding-right: 4px;
  border-bottom:1px solid #cccccc;
  cursor: pointer;
}
</style>
